<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div style="background: #ecf0f5">
    <v-container fluid>
      <v-row style="margin-top: -20px" dense>
        <v-col>
          <v-breadcrumbs :items="items" light>
            <template v-slot:divider>
              <v-icon small>$vuetify.icons.values.mdiChevronRight</v-icon>
            </template>
          </v-breadcrumbs>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span><v-icon large class="mr-3">$vuetify.icons.values.mdiChartPie</v-icon></span>
          <span>{{$t('msg.bulkOrder_page_heading_name')}}</span>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="overlay">
      <v-dialog v-model="overlay" hide-overlay persistent width="300">
        <v-card color="black" dark>
          <v-card-text>
            {{$t('msg.loading')}}
            <v-progress-linear indeterminate color="white" class="mb-0"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
    <v-container fluid style="padding-bottom: 70px">
      <v-row>
        <v-col>
          <v-card>
            <v-app-bar dark color="#3c8dbc">
              <v-toolbar-title>{{$t('msg.table_heading_export')}}</v-toolbar-title>
            </v-app-bar>
            <v-card-title></v-card-title>
            <v-row dense>
              <v-col>
                <v-data-table :headers="headers" :items="itemsWithIndex" :disable-sort="true" :hide-default-footer="true">
                  <template v-slot:item.filters_applied="{ item }">
                    <v-icon small class="mr-2" @click="viewAppliedFilter(item)">
                      $vuetify.icons.values.mdiInformation
                    </v-icon>
                  </template>
                  <template v-slot:item.download="{ item }">
                    <span v-if="item.file_available">
                      <span v-if="checkArray(item.id)">
                        <v-icon class="mr-2" color="green" @click.native="exportExcel(item.id)">
                          $vuetify.icons.values.mdiFileDownloadOutline
                        </v-icon>
                      </span>
                      <span v-else>
                        <v-progress-circular indeterminate :size="20" color="#3c8dbc"/>
                      </span>
                    </span>
                    <span v-else>
                      <v-progress-circular indeterminate :size="20" color="#3c8dbc"/>
                    </span>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <span v-if="item.file_available">
                      <span>Ready</span>
                    </span>
                    <span v-else>
                      <span>Processing</span>
                    </span>
                  </template>
                </v-data-table>
                <v-dialog max-width="450" persistent v-model="showAppliedFilters">
                  <v-card>
                    <v-card-title class="headline">Filters Applied</v-card-title>
                    <v-card-text>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                          <tr>
                            <td>Status</td>
                            <td>:</td>
                            <td v-if="!clickedItem.filters_applied.label">All Status</td>
                            <td v-else>
                              <span>{{clickedItem.filters_applied.label}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>From</td>
                            <td>:</td>
                            <td v-if="!clickedItem.filters_applied.startDate">All Dates</td>
                            <td v-else>
                              <span>{{clickedItem.filters_applied.startDate}}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>To</td>
                            <td>:</td>
                            <td v-if="!clickedItem.filters_applied.endDate">All Dates</td>
                            <td v-else>
                              <span>{{clickedItem.filters_applied.endDate}}</span>
                            </td>
                          </tr>
                          <tr v-show="clickedItem.filters_applied.searchText">
                            <td>Searched By</td>
                            <td>:</td>
                            <td>
                              <span>{{clickedItem.filters_applied.searchText}}</span>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" class="mr-4" dark @click="showAppliedFilters=false" right>Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-card class="dashboard-datatable-footer dashboardFooterBottomMargin">
              <v-card-actions class="dashboard-datatable-footer-actions">
                Note: System displays only the last 5 attempts to export data
              </v-card-actions>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="reportDownloadError" :bottom="true" dark color="#3c8dbc" :right="true" :timeout="6000"
                :vertical="true">
      <span v-html="$t('msg.export_unavailable')"></span>
      <v-btn dark text @click="reportDownloadError = false" v-html="$t('msg.close_label')"></v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { dashboardService, downloadFileService } from '../_services'
import fileSaver from 'file-saver'

export default {
  name: 'BulkDownload',
  data () {
    return {
      overlay: false,
      clickedItem: {},
      showAppliedFilters: false,
      statusDropDown: [],
      repeater: null,
      requestDataArray: [''],
      tableBody: [],
      date: new Date().toJSON(),
      loadingDataTable: false,
      reportDownloadError: false,
      dialog: false,
      table: {
        tableData: [],
        totalLength: 0
      },
      items: [
        {
          text: 'Home',
          disabled: true,
          href: 'breadcrumbs_dashboar'
        },
        {
          text: 'Result',
          disabled: true,
          href: 'breadcrumbs_link_1'
        }
      ],
      headers: [
        { text: this.$t('msg.table_header_no'), align: 'center', value: 'index' },
        { text: this.$t('msg.table_header_date'), align: 'center', value: 'date_requested_on' },
        { text: this.$t('msg.table_header_filter_applied'), align: 'center', sortable: false, value: 'filters_applied' },
        { text: this.$t('msg.table_header_status'), align: 'center', sortable: false, value: 'status' },
        { text: this.$t('msg.table_header_download'), align: 'center', sortable: false, value: 'download' }
      ]
    }
  },
  methods: {
    exportExcel: function (id) {
      this.reportDownloadError = false
      let loaderString = id
      if (this.requestDataArray.indexOf(loaderString) < 0) {
        this.requestDataArray.push(loaderString)
      }
      downloadFileService.downloadSavedExcel({ id: id })
        .then(response => {
          if (response) {
            fileSaver.saveAs(response, 'excel_export.xlsx')
          }
          this.requestDataArray.splice(this.requestDataArray.indexOf(loaderString), 1)
          this.loadingDataTable = false
        }).catch(error => {
          this.loadingDataTable = false
          this.requestDataArray.splice(this.requestDataArray.indexOf(loaderString), 1)
          this.reportDownloadError = true
          /* eslint-disable no-console */
          console.log('loggin response ' + error)
        /* eslint-enable no-console */
        })
    },
    checkArray: function (id) {
      return this.requestDataArray.indexOf(id) < 0
    },
    viewAppliedFilter (item) {
      this.showAppliedFilters = true
      this.clickedItem = item
    },
    getAllFiltersRequested: function () {
      this.overlay = true
      clearInterval(this.repeater)
      dashboardService.fetchRequestedFilters()
        .then(response => {
          if (response.dataResponse) {
            if (response.dataResponse.tableData) {
              this.tableBody = response.dataResponse.tableData
              for (let i = 0; i < this.tableBody.length; i++) {
                if (!this.tableBody[i].file_available) {
                  this.repeater = setInterval(() => {
                    this.getAllFiltersRequested()
                  }, 5000)
                }
              }
            }
          }
          this.overlay = false
        }).catch(error => {
          this.overlay = false
          /* eslint-disable no-console */
          console.log('loggin response' + error)
        /* eslint-enable no-console */
        })
    }
  },
  computed: {
    isShowExportButtonEnabled: function () {
      return this.$store.state.home.user.showExportButton
    },
    itemsWithIndex: function () {
      return this.tableBody.map(
        (item, index) => ({
          ...item,
          index: index + 1
        }))
    }
  },
  mounted () {
    this.$store.dispatch('home/setCurrentPage', 'bulkdownload')
    this.$cookies.set('standard_current_page', 'bulkdownload')
    this.getAllFiltersRequested()
  },
  beforeDestroy () {
    clearInterval(this.repeater)
  }
}
</script>

<style scoped>
  .v-breadcrumbs {
    padding: 7px 0px 0px 0px !important;
  }
</style>
<style>
  .v-input input {
    cursor: pointer !important;
  }

  .v-input__slot {
    cursor: default !important;
  }
</style>
